.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Hide scrollbars */
body {
  overflow: hidden;
}

.drawerBox {
  overflow-x: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  z-index: -1,
}

.maplibregl-popup-content {
  box-shadow: 0px 6px 12px 6px rgba(0, 0, 0, 0.5);
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.div-filter {
  padding-left: 6px;
}

.div-commute {
  padding-left: 6px;
}

/* ref: https://stackoverflow.com/questions/23935758/gradient-over-img-tag-using-css */

.img-gradient {
  position: relative;
  display: inline-block;
}

.img-card {
  opacity: 1;
  position: relative;
}

.img-carousel {
  z-index: 1;
  min-width: 300px;
}

.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.55)),
    color-stop(100%, rgba(0, 0, 0, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.img-container {
  display: block;
  min-width: 300px;
  width: 100%;
  height: 100%;
}

.stack-btns {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.img-bottom-left {
  opacity: 1;
  z-index: 2;
  color: white;
  text-align: left;
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.img-price {
  font-size: 26px;
  padding-bottom: 6px;
}

.img-addr-street {
  font-size: 15px;
}

.img-posted {
  font-size: 15px;
}

.img-addr-locality {
  font-size: 12px;
}

.img-sqft {
  font-size: 15px;
}

.img-bedroom {
  font-size: 15px;
}

.img-bathroom {
  font-size: 15px;
}

.img-bottom-right {
  opacity: 1;
  z-index: 2;
  color: white;
  text-align: left;
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.apa-list {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
}
