.HomeContainer {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HomePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HistogramImage {
  margin-top: -10px;
  height: auto;
  width: 825px;
  transform: perspective(900px) rotateY(-20deg);
}

.IsochroneImage {
  margin-top: -10px;
  height: auto;
  width: 840px;
  transform: perspective(900px) rotateY(-20deg);
}

.HomePage {
  overflow-x: hidden;
  overflow-y: auto;
}
