.SearchBoxTextField {
  width: 70%;
  padding-bottom: 30px;
}

.SearchBoxButton {
  /* color: var(--color-background); */
  width: 70%;
  border-radius: 40px;
}

.SearchBoxCard {
  background: transparent;
  border: none !important;
}

.SearchBoxCardHeader {
  text-align: center;
}

.SearchBoxCardContent {
  text-align: center;
}

.SearchBoxCardAction {
  justify-content: center;
}
